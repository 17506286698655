.container{
    max-width: 1108px;
  }
  
  .cra-header{
    background:  url("./img/header.png") no-repeat center;
    background-size: auto;
  }
  

  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .new .row h1 {
    font-size: 3.2rem;
    /* padding-bottom: 40px; */
    min-width: 200px;
    min-height: 100px;
}
  

.imageDiv
{
    margin-left: 500px;
    background: #fff;
    color:white;
    display: block;
    height: 450px;

    border-radius: 2px 2px 2px 2px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    /*background-image: linear-gradient(to top, rgba(255,0,0,0.5), rgb(240, 146, 31));*/
    border-top-left-radius: 15%;
    border-top-right-radius: 15%;
    text-align: right;
}

.new .card-title{
    min-height: 100px;
    max-width: 200px;
}


.boxDiv
{
    background: #fff;
    color:white;
    display: block;
    max-width: 530px;
    height: 450px;
    padding: 10px;

    border-radius: 2px 2px 2px 2px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    /*background-image: linear-gradient(to top, rgba(255,0,0,0.5), rgb(240, 146, 31));*/
    text-align: right;
}


.bottomDiv{
    position:relative;
    bottom:400px;
    right:150px;
    max-width:450px;
    /*background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(7, 173, 83));*/
    
    
}

.bottomDivTwo{
    position:relative;
    bottom:750px;
    right:300px;
    max-width:350px;
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 122, 217));*/
    
   
}

.bottomDivThree{
    position:relative;
    bottom:1050px;
    right:470px;
    max-width:300px;
    /*background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(214, 28, 87));*/
    text-align: center;
}


.borderDot{
    border: dashed 1px white;
    border-right: none;
    border-left: none;
    border-bottom: none;
    
    width:100%;
}

.textPercentage{
    font-weight: 800;
    font-size: 18px;
}

.textNumber{
    font-weight: 800;
    font-size: 14px;
}


.visual-header {
    color: white;
    text-align: left;
    margin-bottom: 1vw;
    font-size:2em;
}

.combined-title{
    max-width: 150px;
    
}





.new .row h1{
    font-size: 3.2rem;
    padding-bottom: 40px;
  }
  

.select-box-label{
    text-align: left;
    font: normal normal normal 18px/22px Tondo;
    letter-spacing: 3.6px;
    color: #071F41;
    opacity: 1;
}

.select-control {

    box-shadow: 0px 10px 10px #00000033;
    border: none !important;

}


.card-header-title {

    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.box-alt{
    width: 100%;
}

.alert-steps {
    display:none;
}

.reach-box {
    display: none;
   
}

@media (min-width: 830px) {

    .visual-header {
        padding-left: 50px;

    }

    .alert-steps {
        color: #fff;
        background-color: rgb(0, 174, 239);
        border-color: rgb(0, 174, 239);
        box-shadow: 0px 10px 10px #00000033;
        display:block
    }

    .box-alt{
        position: absolute;
    }

    .mediaone{
        width: 20rem;
    
    }

    .mediatwo{
        width: 20rem;
    }

    .mediathree{
        width: 18rem;
    }

    .mediafour{
        width: 16rem;
    }
    
    
    .graph{
        height:425px;
        margin-left: auto;
       
    }

    .boxDiv
    {
    
        border-top-left-radius: 15%;
        border-top-right-radius: 15%;

    }

    .reach-box {
        display: flex;
        flex-direction: row;
        background-color: white;
        justify-content: space-evenly;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
        border: solid 1px black;
    }

  }

  .list-group-item {
    border: none;
}